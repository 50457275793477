/*
import { try } from 'q';
 * @Author: wangqs 
 * @description 测试模块
 * @Date: 2021-07-11 16:24:29 
 * @Last Modified by: wangqs
 * @Last Modified time: 2023-04-09 16:01:33
 */
<template>
  <div class="page-box">
    <template v-if="query.school_id">
      <div
        v-if="schoolStatus"
        class="box box-a"
      >
        <div class="img-box">
          <van-image :src="require('@/assets/img/check-img-a.png')">
          </van-image>
        </div>
        <div class="tip-text">
          是否要绑定成为核销人员？
        </div>
        <div class="btn-box box">
          <!-- <div class="btn-a">
            取消
          </div> -->
          <div
            class="btn-b"
            @click="operatorAdd"
          >
            确定
          </div>
        </div>
      </div>
      <div
        v-else
        class="box box-b"
      >
        <div class="img-box">
          <van-image :src="require('@/assets/img/check-img-b.png')">
          </van-image>
        </div>
        <div class="tip-text">
          绑定成功！
        </div>
      </div>
    </template>
    <tempalte v-else>
      <template v-if="detail && checkStatus === -1">
        <check-detail
          ref="checkDetail"
          :detail="detail"
        />
        <div class="detail-btn-box">
          <div
            class="btn btn-a"
            @click="checkStatus = 2"
          >
            取消
          </div>
          <div
            class="btn btn-b"
            @click="checkBtn"
          >
            确定
          </div>
        </div>
      </template>
      <div
        v-if="checkStatus === 1"
        class="box box-b"
      >
        <div class="img-box">
          <van-image :src="require('@/assets/img/check-img-b.png')">
          </van-image>
        </div>
        <div class="tip-text">
          核销成功！
        </div>
      </div>
      <div
        v-else-if="checkStatus === 2"
        class="box box-c"
      >
        <div class="img-box">
          <van-image :src="require('@/assets/img/check-img-c.png')">
          </van-image>
        </div>
        <div class="tip-text">
          核销失败！
        </div>
      </div>
    </tempalte>
  </div>
</template>

<script>
import CheckDetail from "./components/checkDetail"
export default {
  name: 'CheckPage',
  components: {
    CheckDetail
  },
  data () {
    return {
      query: {},
      schoolStatus: true,
      checkStatus: -1,
      detail: null,
    }
  },
  computed: {
  },
  async created () {
    this.$toast.loading({
      message: '加载中',
      forbidClick: true
    });
    console.log(this.$route.query, 'checkquery')
    this.query = this.$route.query
    console.log('jinru')
    if (!this.query.school_id) {
      let res = await this.$http.userApi.checkActionInfo({uuid: this.query.uuid})
      console.log(res, 'res')
      if (res.code == 200) {
        this.detail = res.msg || {}
      }
      
    } else {
      this.$toast.clear()
    }
  },
  methods: {
    async checkBtn () {
      let times = this.$refs.checkDetail.times
      if (times) {
        let res = await this.$http.userApi.checkAction({ uuid: this.query.uuid, times: Number(times) })
        if (res.code === 200) {
          this.$toast.clear()
          this.checkStatus = 1
        }
      } else {
        this.$toast('请填写核销次数')
      }
    },
    async operatorAdd () {
      this.$toast.loading({
        message: '加载中',
        forbidClick: true
      });
      let data = {
        schoolId: this.query.school_id,
        uuid: this.query.uuid
      }
      let res = await this.$http.userApi.operatorAdd(data)
      console.log(res, 'res')
      if (res.code === 200) {
        this.$toast.clear()
        this.schoolStatus = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  width: 100%;
  min-height: 100%;
  .img-box {
      margin: 75px auto 28px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  .box-a {
    .img-box {
      margin: 115px auto 37px;
      width: 122px;
      height: 42px;
    }
  }
  .box-b, .box-c {
    .img-box {
      width: 112px;
      height: 93px;
    }
  }
  .box-a {
    .img-box {
      margin: 115px auto 37px;
      width: 122px;
      height: 42px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .tip-text {
    color: #3D4258;
    font-size: 16px;
    line-height: 23px;
    width: 100%;
    text-align: center;
  }
  .btn-box {
    margin-top: 44px;
    text-align: center;
    .btn-a {
      width: 110px;
      height: 40px;
      line-height: 38px;
      border: 1px solid #4A77FF;
      color: #4A77FF;
      font-size: 16px;
      border-radius: 20px;
      display: inline-block;
      margin-right: 25px;
    }
    .btn-b {
      width: 110px;
      height: 40px;
      line-height: 40px;
      background: #4A77FF;
      color: #fff;
      font-size: 16px;
      border-radius: 20px;
      display: inline-block;
    }
  }
  .detail-btn-box {
    width: 100%;
    height: 50px;
    line-height: 50px;
    position: absolute;
    bottom: 0;
    left: 0;
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.4);
    .btn {
      width: 50%;
      text-align: center;
      font-weight: 600;
      font-size: 16px;
      float: left;
    }
    .btn-b {
      background: #4A77FF;
      color: #fff;
    }
  }
}
</style>
